<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :selectable="false"
      :columns="columns"
      :type="type"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'departments',
      addType: 'Add Departments',
      addComponentName: 'add-departments',
      editComponent: 'edit-departments',
      viewContent: true,
      type: 'page',
      columns: [
        // { key: 'id', sortable: false },
        { key: 'title', label: 'Title' },
        { key: 'title_ar', label: 'Title Ar' },
        { key: 'title_en', label: 'Title En' },
        { key: 'description', label: 'Description' },
        { key: 'description_ar', label: 'Description Ar' },
        { key: 'description_en', label: 'Description En' },
        // { key: 'actions' },
      ],
    }
  },
  methods: {

  },
}
</script>

<style>

</style>
